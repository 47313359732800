// import React from "react";

// const WeekData = ({ weeks }) => {
// 	console.log(weeks[0].chapters[0].lessons);
// 	return (
// 		<div className="border-solid border-black border-2 p-2 text-center">
// 			<h2>{weeks[0].weekTitle}</h2>
// 			<p>{weeks[0].weekDescription}</p>
// 			<ul>
// 				{weeks[0].chapters.map((chapter, index) => (
// 					<li key={index}>
// 						<h4>{chapter.chapterTitle}</h4>
// 						<ul>
// 							{chapter.lessons.map((lesson, index) =>
// 								lesson.lessonType === "video" ? (
// 									<li key={index} className="text-left">
// 										<div className="flex gap-4">
// 											<img src="" alt={lesson.lessonType} />
// 											<a href={lesson.videoURL}>
// 												<h5>{lesson.lessonTitle}</h5>
// 											</a>
// 										</div>
// 										<p>{lesson.lessonType}</p>
// 									</li>
// 								) : lesson.lessonType === "readings" ? (
// 									<li key={index} className="text-left">
// 										<div className="flex gap-4">
// 											<img src="" alt={lesson.lessonType} />
// 											<a href={lesson.readingsURL}>
// 												<h5>{lesson.lessonTitle}</h5>
// 											</a>
// 										</div>
// 										<p>{lesson.lessonType}</p>
// 									</li>
// 								) : (
// 									<li key={index} className="text-left">
// 										<div className="flex gap-4">
// 											<img src="" alt={lesson.lessonType} />
// 											<a href={lesson.quizURL}>
// 												<h5>{lesson.lessonTitle}</h5>
// 											</a>
// 										</div>
// 										<p>{lesson.lessonType}</p>
// 									</li>
// 								)
// 							)}
// 						</ul>
// 					</li>
// 				))}
// 			</ul>
// 		</div>
// 	);
// };

// export default WeekData;

import React from "react";
import {
	VideoCameraIcon,
	BookOpenIcon,
	PuzzlePieceIcon,
} from "@heroicons/react/24/solid";

const WeekData = ({ weeks }) => {
	console.log(weeks[0].chapters[0].lessons);
	return (
		<div className="border-2 border-gray-300 rounded-lg bg-white shadow-lg">
			<h2 className="text-2xl font-bold p-4 text-center mb-4 bg-purple-400">
				{weeks[0].weekTitle}
			</h2>
			<p className="text-gray-600 mb-8 px-8">{weeks[0].weekDescription}</p>

			<ul className="w-full">
				{weeks[0].chapters.map((chapter, index) => (
					<li key={index} className="mb-6">
						<h4 className="text-xl font-semibold text-center mb-3">
							{chapter.chapterTitle}
						</h4>

						<ul className="space-y-4">
							{chapter.lessons.map((lesson, index) => (
								<li key={index} className="text-left">
									<div className="flex items-center gap-4">
										{lesson.lessonType === "video" ? (
											<VideoCameraIcon className="w-8 h-8 text-gray-600" />
										) : lesson.lessonType === "readings" ? (
											<BookOpenIcon className="w-8 h-8 text-gray-600" />
										) : (
											<PuzzlePieceIcon className="w-8 h-8 text-gray-600" />
										)}
										<a
											href={
												lesson.lessonType === "video"
													? lesson.videoURL
													: lesson.lessonType === "readings"
													? lesson.readingsURL
													: lesson.quizURL
											}
											className="text-lg font-medium text-black no-underline"
										>
											{lesson.lessonTitle}
										</a>
									</div>
									<p className="text-sm text-gray-500 ml-12 mt-1">
										{lesson.lessonType.charAt(0).toUpperCase() +
											lesson.lessonType.slice(1)}
									</p>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</div>
	);
};

export default WeekData;
