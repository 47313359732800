import React, { useState, useEffect } from "react";
import CourseWeeks from "./CourseWeeks";
import WeekData from "./WeekData";
import InstructorData from "./InstructorData";
import API from "../Api";
import Footer from "./Footer";

const CoursePage = () => {
	const [courseData, setCourseData] = useState({
		weeks: [
			{
				chapters: [{ lessons: [] }],
			},
		],
	});

	useEffect(() => {
		const fetchCourse = async () => {
			try {
				const response = await API.get("course/1/");
				setCourseData(response.data.course);
				// console.log(response.data.course);
			} catch (error) {
				console.error("Error fetching courses:", error);
			}
		};

		fetchCourse();
	}, []);

	console.log(courseData);

	return (
		<>
			<div className="flex h-auto justify-center p-2">
				<div className="w-3/12 h-full flex flex-col items-center p-4">
					<CourseWeeks title={courseData.title} weeks={courseData.weeks} />
				</div>
				<div className="w-px ml-2 mr-2 bg-gray-400 h-full"></div>
				<div className="w-6/12 h-full flex flex-col items-center p-4">
					<WeekData weeks={courseData.weeks} />
				</div>
				<div className="w-px ml-2 mr-2 bg-gray-400 h-full"></div>
				<div className="w-3/12 h-full flex flex-col items-center p-4">
					<InstructorData />
				</div>
			</div>
			<Footer />
		</>
	);
};

export default CoursePage;
