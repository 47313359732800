// import React from "react";
// import pythonLogo from "../pythonsvg.svg";

// const CourseWeeks = ({ title, weeks }) => {
// 	// console.log(weeks);
// 	return (
// 		<>
// 			<img src={pythonLogo} alt="Python logo" />
// 			<h2>{title}</h2>
// 			<h3>Shuniyavigyan</h3>

// 			<h1>Week</h1>
// 			{weeks.map((week, index) => (
// 				<div key={index}>
// 					<h2>Week {index + 1}</h2>
// 				</div>
// 			))}
// 		</>
// 	);
// };

// export default CourseWeeks;

import React from "react";
import pythonLogo from "../pythonsvg.svg";
import { CheckCircleIcon } from "@heroicons/react/solid";

const CourseWeeks = ({ title, weeks }) => {
	return (
		<div className="flex flex-col items-center p-4 bg-white shadow-md h-full">
			<img
				src={pythonLogo}
				alt="Python logo"
				className="w-24 h-24 rounded-full mb-4"
			/>

			<h2 className="text-xl font-bold">{title}</h2>
			<h3 className="text-gray-500 mb-6">Shuniyavigyan</h3>

			<h1 className="text-lg font-semibold mb-4">WEEK</h1>

			<div className="w-full">
				{weeks.map((week, index) => (
					<div
						key={index}
						className={`flex items-center justify-between p-3 rounded-lg mb-2 cursor-pointer transition-all ${
							week.completed
								? "bg-green-100"
								: index === 1
								? "bg-purple-200"
								: "bg-gray-100"
						}`}
					>
						<h2 className="text-lg font-medium">Week {index + 1}</h2>
						{week.completed && (
							<CheckCircleIcon className="w-6 h-6 text-green-600" />
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default CourseWeeks;
