import React, { useEffect, useRef, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';   
import Applogo from "./assets/images/appLogo.png"; 
import { useAuth } from './AuthContext';
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";  
import User from './assets/images/User_login_input.png';  
import { useTranslation } from 'react-i18next';
import Cancel from './assets/images/cancel_register.png';
import Phone from './assets/images/phone_login.png';
import Google from './assets/images/Google.png';
import { auth } from './Firebase.js';
import { GoogleAuthProvider,signInWithPopup,PhoneAuthProvider,RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ModelLogin = ({ modelLogin, handleClose, openRegister }) => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (modelLogin) {
      reset(); // Clear input data
    }
  }, [modelLogin, reset]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClose]);

  if (!modelLogin) return null;

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('username', data.username);
    formData.append('password', data.password);
    
    const apiUrl = 'https://xn--11by0j.com/api/v1/register_or_login/';
    
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
      });
  
      const responseData = await response.json(); // Parse response as JSON
  
      if (response.ok) {
        toast.success('Login successful!');
        localStorage.setItem('user', JSON.stringify(responseData.user_id));
        login();
        handleClose();
        navigate('/');
      } else {
        toast.error(`Login failed: ${responseData.message || 'Please try again.'}`);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      
      // Store user data in localStorage
      const user = result.user;
      const userData = {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL
      };
      
      localStorage.setItem('user', JSON.stringify(userData));
      
      toast.success('Google Sign-In successful!');
      login();
      handleClose();
      navigate('/');
    } catch (error) {
      toast.error("Google Sign-In Error.");
      console.error("Google Sign-In Error:", error);
    }
  };
  

  return (
    <div className='relative'>
      <div className="absolute top-[16.5rem] inset-0 bg-opacity-50 flex justify-end items-center z-20">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-[400px] bg-white p-6 rounded-xl shadow-md"
          ref={modalRef}
        >
          <div className='relative'>
            <img src={Cancel} alt="" className='absolute top-0 right-0 hover:cursor-pointer' onClick={handleClose} />
          </div>
          <h2 className="text-2xl font-bold text-[#7E22CE] text-center my-6">
            {t("login.log")}
          </h2>
          <div className='flex flex-col items-center text-black'>
            <span>{t('login.title1')}</span>
            <span>{t('login.title2')}</span>
          </div>

          {/* Google Login */}
          <button onClick={handleGoogleLogin} className="w-full mt-3 text-black border-2 flex justify-center items-center border-gray-300 gap-3 py-2 text-lg rounded-lg font-semibold">
            <img src={Google} alt="Google" />
            <span className='text-[#5E5E5E]'>{t("login.google")} </span>
          </button>

          <div className='flex justify-center items-center gap-2'>
          <div className='h-[1px] w-full bg-neutral-300 mt-3 ' ></div>
          <h4 className='text-black text-center mt-3'>{t("login.or")}</h4>
          <div className='h-[1px] w-full bg-neutral-300 mt-3'></div>
          </div>

          {/* Username and Password Login */}
          {/* Username Input */}
          <div className="my-6">
            <div className="flex items-center border-2 border-gray-300 rounded-lg px-3 py-2">
              <img src={User} alt="User Icon" className="w-5 h-5" />
              <input
                type="text"
                placeholder={t("login.user")}
                {...register('username')}
                className="w-full ml-3 text-gray-700 focus:outline-none border-gray-300 bg-white"
              />
              {errors.username && <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />}
            </div>
            {errors.username && <span className="text-red-500 text-sm">{errors.username.message}</span>}
          </div>

          {/* Password Input */}
          <div className="mb-6">
            <div className="flex items-center border-2 border-gray-300 rounded-lg px-3 py-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none" className="w-5 h-5">
                <path d="M14.0625 7.875H13.2188V5.34375C13.2188 2.39766 10.8211 0 7.875 0C4.92891 0 2.53125 2.39766 2.53125 5.34375V7.875H1.6875C0.755859 7.875 0 8.63086 0 9.5625V16.3125C0 17.2441 0.755859 18 1.6875 18H14.0625C14.9941 18 15.75 17.2441 15.75 16.3125V9.5625C15.75 8.63086 14.9941 7.875 14.0625 7.875ZM4.21875 5.34375C4.21875 3.24531 5.77656 1.6875 7.875 1.6875C9.97344 1.6875 11.5312 3.24531 11.5312 5.34375V7.875H4.21875V5.34375ZM14.0625 16.3125H1.6875V9.5625H14.0625V16.3125ZM7.875 13.5C8.65664 13.5 9.28125 12.8754 9.28125 12.0938C9.28125 11.3121 8.65664 10.6875 7.875 10.6875C7.09336 10.6875 6.46875 11.3121 6.46875 12.0938C6.46875 12.8754 7.09336 13.5 7.875 13.5Z" fill="#404040" />
              </svg>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder={t("login.password")}
                {...register('password')}
                className="w-full ml-3 text-gray-700 focus:outline-none border-gray-300 bg-white"
              />
              <button
                type="button"
                className="text-gray-600 focus:outline-none"
                onClick={() => setShowPassword(!showPassword)}
              >
                {/* {showPassword ? 'Hide' : 'Show'} */}
              </button>
              {errors.password && <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />}
            </div>
            {errors.password && <span className="text-red-500 text-sm">{errors.password.message}</span>}
          </div>

          <button
            type="submit"
            className="w-full bg-[#7E22CE] text-white py-2 px-4 rounded-lg font-semibold hover:bg-[#4B0082]"
          >
            {t("login.log")}
          </button>

          <p className="text-center text-[#5E5E5E] mt-4">{t("login.have")} <Link to="" className="text-[#4B0082]" onClick={openRegister}>{t("login.register")}</Link></p>
        </form>
      </div>
    </div>
  );
};

export default ModelLogin;
