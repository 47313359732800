import React, { useEffect, useState,useRef } from 'react';
import { Link } from "react-router-dom";
import { useAuth } from './AuthContext';
import logo from "./appLogo.png";
import Profile from "./assets/images/profileLogo.png";
import ProfileDD from "./assets/images/profileDropdown.png";
import theme from "./assets/images/themeLogo.png";
import hamburgerIcon from './assets/images/hamburger-Navbar.com.svg'; // Import a hamburger icon
import Cancel from './assets/images/cross_Navbar.com.svg'
import { useTranslation } from 'react-i18next';
import ModelLogin from './ModelLogin.js';
import LogOut from './assets/icons/logout.svg'
import ModelRegister from './ModelRegister.js';
import PaymentButton from './PaymentButton.js'


const CustomNavbar = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // State for hamburger menu
    // const [modalType, setModalType] = useState(null);
    const dropdownRef = useRef(null);
    const [modelLogin,setModelLogin] = useState(false);
    const [modelRegister,setModelRegister] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const { isLoggedIn, logout } = useAuth();
    const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('हिंदी');
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const languageDropdownRef = useRef(null);

    useEffect(() => {
      const handleScroll = () => {
          const scrollPosition = window.scrollY;
          // Change the scroll threshold as needed (50vh)
          if (scrollPosition > window.innerHeight / 2) {
              setScrolled(true);
          } else {
              setScrolled(false);
          }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  useEffect(() => {
    // Retrieve language from localStorage and set it if available
    const savedLanguage = localStorage.getItem('preferredLanguage') || 'हिंदी'; // Default to 'हिंदी' if no language is saved
    setSelectedLanguage(savedLanguage);
    i18n.changeLanguage(savedLanguage === 'English' ? 'en' : 'hn');
}, [i18n]);

    const handleLogin = () => {
      setModelLogin(true);
      setModelRegister(false);
    };

    const handleRegister = () => {
      setModelRegister(true);
      setModelLogin(false);
    };

    const handleCloseModal = () => {
      setModelLogin(false);
      setModelRegister(false);
    };

    const changeLanguage = (lang) => {
      i18n.changeLanguage(lang);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleLanguageDropdown = () => {
        setLanguageDropdownOpen(!languageDropdownOpen);
      };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // const handleLanguageChange = (lang) => {
    //     // Implement your language change logic here
    //     console.log(`Language changed to: ${lang}`);
    //     setLanguageDropdownOpen(false);
    //   };

    const handleLogout = () => {
      localStorage.removeItem('user');
      logout();
      setDropdownOpen(false);
      setMenuOpen(false);
    };

    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
      if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
        setLanguageDropdownOpen(false);
      }
    };
  
    useEffect(() => {
      // Add event listener for clicks
      document.addEventListener('mousedown', handleClickOutside);
      // Clean up the event listener
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
    

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        localStorage.setItem('preferredLanguage', language);
        setLanguageDropdownOpen(false); // Close the dropdown after selection
    };

    return (
        <nav className={`py-2 px-4 md:px-20 lg:px-36 xl:px-52 2xl:px-80 sticky top-0 w-full h-18 md:h-20 z-50 transition-all duration-300 ${
          scrolled ? 'bg-white border-b border-neutral-300' : 'bg-transparent  text-white'
          }`}>
        <div className="flex justify-between items-center lg:gap-10">
          {/* Logo */}
          <div className="flex items-center">
            <img src={logo} alt="logo" className={`rounded-full border-2  md:w-12 md:h-12 w-8 h-8 ${scrolled ? 'border-black' : 'border-white'}`} />
            <span className={`text-lg md:text-2xl font-bold px-2  logoName tracking-wider ${scrolled ? 'text-neutral-800' : 'text-white'}`}>{t('header.logo')}</span>
          </div>
      
          {/* Desktop Menu Links */}
          <div className="hidden lg:flex gap-10 mt-3 justify-center items-center">
            <ul className="flex lg:gap-10 md:gap-5 leading-10 mt-3 list-none">
              <li>
                <Link to="/" className={` hover:text-purple-600 hover:font-bold no-underline lg:text-lg md:text-sm font-semibold ${scrolled ? 'text-neutral-700' : 'text-white'}`}>{t('header.home')}</Link>
              </li>
              <li>
                <Link to="/editor" className={` hover:text-purple-600 hover:font-bold no-underline lg:text-lg md:text-sm font-semibold ${scrolled ? 'text-neutral-700' : 'text-white'}`}>{t('header.editor')}</Link>
              </li>
              <li>
                <Link to="/games" className={` hover:text-purple-600 hover:font-bold no-underline lg:text-lg md:text-sm font-semibold ${scrolled ? 'text-neutral-700' : 'text-white'}`}>{t('header.game')}</Link>
              </li>
              <li>
                <Link to="/courses" className={` hover:text-purple-600 hover:font-bold no-underline lg:text-lg md:text-sm font-semibold ${scrolled ? 'text-neutral-700' : 'text-white'}`}>{t('header.course')}</Link>
              </li>
              <li>
                <Link to="/downloads" className={` hover:text-purple-600 hover:font-bold no-underline lg:text-lg md:text-sm font-semibold ${scrolled ? 'text-neutral-700' : 'text-white'}`}>{t('header.download')}</Link>
              </li>
            </ul>

            {/* Language Dropdown and Profile */}
          <div className="flex items-center gap-1 md:gap-4 lg:gap-4 xl:gap-4">
            {/* <button className=' bg-purple-600 px-3 py-2 rounded-xl text-white'>
              Get Membership
            </button> */}
            <PaymentButton />
            {/* Language Dropdown */}
            <div className="relative" ref={languageDropdownRef}>
              <button
                className={`flex items-center border-2 px-1 py-1 md:px-2 rounded-xl md:py-2 hover:text-purple-600 hover:font-bold no-underline  md:text-lg font-semibold ${scrolled ? ' text-neutral-700' : 'text-white'}`}
                onClick={toggleLanguageDropdown}
              >
                <span>{selectedLanguage}</span>
                <svg
                  className={`w-5 h-5 ml-1 transition-transform ${languageDropdownOpen ? 'rotate-180' : ''}`}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {languageDropdownOpen && (
                <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow-lg z-50">
                  <button onClick={() => {handleLanguageChange('English'); changeLanguage('en')}} className={`block w-full text-center py-2 ${selectedLanguage === 'English' ? 'bg-purple-600 text-white' : 'hover:bg-purple-400 text-black'}`}>
                    English
                  </button>
                  <button onClick={() => {handleLanguageChange('हिंदी'); changeLanguage('hn')}} className={`block w-full text-center py-2 ${selectedLanguage === 'हिंदी' ? 'bg-purple-600 text-white' : 'hover:bg-purple-400 text-black'}`}>
                  {t('header.hindi')}
                  </button>
                </div>
              )}
            </div>
      
            {/* Profile & Login/Logout */}
            {isLoggedIn ? (
              <div className="relative flex items-center" ref={dropdownRef}>
                {/* Profile visible in Navbar */}
                <img src={Profile} alt="Profile" className="rounded-full w-8 h-8 md:w-12 md:h-12 cursor-pointer" onClick={toggleDropdown} />
                {dropdownOpen && (
                  <div className="absolute top-12 right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg z-50 flex flex-col ">
                    <div className="flex items-center gap-3 pl-8 py-2 border-b hover:bg-gray-200 duration-300 cursor-pointer">
                          <img src={ProfileDD} alt="" />
                          <Link to="/profile" className="no-underline text-black">Profile</Link>
                    </div>
                    <div className="flex items-center gap-3 pl-8 py-2 border-b hover:bg-gray-200 duration-300 cursor-pointer">
                      <img src={ProfileDD} alt="" />
                      <Link to="/portfolio" className="no-underline text-black">Portfolio</Link>
                    </div>
                    <div className="flex items-center gap-3 pl-8 py-2 border-b hover:bg-gray-200 duration-300 cursor-pointer">
                      <img src={theme} alt="" />
                      <Link to="/dark-theme" className="no-underline text-black">Dark Theme</Link>
                    </div>
                    <div className='flex itemscenter gap-3 pl-8 py-2 border-b hover:bg-gray-200 duration-300 cursor-pointer' onClick={handleLogout}>
                      <img src={LogOut} alt="" />
                      <div className='text-black'>Log Out</div>
                    </div>

                  </div>
                )}
              </div>
            ) : 
            <button className='hidden lg:flex bg-purple-600 px-2 py-2 rounded-xl text-white'><div onClick={handleLogin} className='no-underline text-white px-1'>{t('header.sign')}</div> | <div onClick={handleRegister} className='no-underline text-white px-1'> {t('header.register')}</div></button>
            }
      
            {/* Hamburger Menu for Mobile */}
            <div className="md:hidden ">
              <button onClick={toggleMenu}>
                <img src={menuOpen ? Cancel : hamburgerIcon} alt={menuOpen ? 'Close menu' : 'Open menu'} className="w-8 h-8 pt-1" />
              </button>
            </div>
          </div>
          </div>
        </div>
      
        {/* Mobile Menu */}
        {menuOpen && (
          <div className="md:hidden mt-4">
            <ul className="flex flex-col gap-4 bg-gray-100 p-4 rounded-lg shadow-lg">
              <li>
                <Link to="/" className="text-neutral-700 hover:text-purple-600 hover:font-bold no-underline text-lg font-semibold" onClick={toggleMenu}>Home</Link>
              </li>
              <li>
                <Link to="/editor" className="text-neutral-700 hover:text-purple-600 hover:font-bold no-underline text-lg font-semibold" onClick={toggleMenu}>Editor</Link>
              </li>
              <li>
                <Link to="/games" className="text-neutral-700 hover:text-purple-600 hover:font-bold no-underline text-lg font-semibold" onClick={toggleMenu}>Games</Link>
              </li>
              <li>
                <Link to="/courses" className="text-neutral-700 hover:text-purple-600 hover:font-bold no-underline text-lg font-semibold" onClick={toggleMenu}>Courses</Link>
              </li>
              <li>
                <Link to="/downloads" className="text-neutral-700 hover:text-purple-600 hover:font-bold no-underline text-lg font-semibold" onClick={toggleMenu}>Downloads</Link>
              </li>
      
              {/* If the user is not logged in */}
              {!isLoggedIn && (
                <>
                 <li>
                  <button  className="bg-purple-600 px-3 py-2 rounded-xl text-white"><Link to="/login" className='no-underline text-white'>Sing in</Link> | <Link to="/register" className='no-underline text-white'> Register</Link></button>
                </li>
                </>
              )}
      
              {/* If the user is logged in */}
              {isLoggedIn && (
                <li>
                  <button onClick={handleLogout} className="text-neutral-700 hover:text-purple-600 hover:font-bold no-underline text-lg font-semibold">Log Out</button>
                </li>
              )}
            </ul>
          </div>
        )}

        <ModelLogin modelLogin={modelLogin} handleClose={handleCloseModal} openRegister={handleRegister}/>
        <ModelRegister modelRegister={modelRegister} handleClose={handleCloseModal} openLogin={handleLogin} />
      </nav>
      

    );
}

export default CustomNavbar;



// <ul>
//                       <li className="py-2 hover:bg-gray-100 cursor-pointer border-b">
                        // <div className="flex items-center gap-3">
                        //   <img src={ProfileDD} alt="" />
                        //   <Link to="/profile" className="no-underline text-black">Profile</Link>
                        // </div>
//                       </li>
//                       <li className="py-2 hover:bg-gray-100 cursor-pointer border-b">
                        // <div className="flex items-center gap-3">
                        //   <img src={ProfileDD} alt="" />
                        //   <Link to="/portfolio" className="no-underline text-black">Portfolio</Link>
                        // </div>
//                       </li>
//                       <li className="py-2 hover:bg-gray-100 cursor-pointer border-b">
                        // <div className="flex items-center gap-3">
                        //   <img src={theme} alt="" />
                        //   <Link to="/dark-theme" className="no-underline text-black">Dark Theme</Link>
                        // </div>
//                       </li>
//                       <li className="py-2 hover:bg-gray-100 cursor-pointer text-black" >
                        // <div className='flex itemscenter gap-3' onClick={handleLogout}>
                        //   <img src={LogOut} alt="" />
                        //   <div >Log Out</div>
                        // </div>
//                       </li>
//                     </ul>