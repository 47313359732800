import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Background from './assets/images/Game-theme_Games.png';
import Play from './assets/icons/Play-logo_Games.svg';
import axios from 'axios';
import Footer from './Footer';

const Games = () => {
  const [gamesData, setGamesData] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get('https://xn--11by0j.com/api/v1/games/');
        setGamesData(response.data); // Ensure response data matches the structure of your API
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching the games data", error);
      }
    };

    fetchGames();
  }, []); // Empty dependency array to run only once on mount

  const handlePlayClick = (url) => {
    if (url) {
      const encodedUrl = encodeURIComponent(url);// Redirect to the provided URL
      navigate(`/game/${encodedUrl}`);
    } else {
      console.error('No play URL provided');
    }
  };

  return (
    <div>
    <div className='relative h-[200px] sm:h-[250px] md:h-[300px] lg:h-[350px] xl:h-[400px] w-full'>
      {/* Background Image */}
      <div className='h-full w-full' style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
  
      {/* Shadow Overlay */}
      <div className='absolute top-0 left-0 h-full w-full flex justify-center items-center' style={{ backgroundColor: '#00000075' }}>
        <h1 className='text-white text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl' style={{ fontFamily: 'CustomFont, sans-serif' }}>GAMES</h1>
      </div>
    </div>
  
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-8 justify-items-center align-items-center my-8 mx-4 sm:mx-6 md:mx-10 lg:mx-16 xl:mx-20">
      {gamesData.map((game, index) => (
       <div
       key={index}
       className="w-[280px] sm:w-[240px] lg:w-[280px] xl:w-[280px] h-[360px] sm:h-[280px] md:h-[300px] lg:h-[330px] xl:h-[370px] border-2 rounded-lg shadow-lg relative overflow-hidden"
       style={{ background: `url(${game.backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
       onMouseEnter={() => setHoveredIndex(index)} // Set hover state
       onMouseLeave={() => setHoveredIndex(null)} // Reset hover state
     >
       {/* Hover GIF */}
       {hoveredIndex === index && (
         <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-30'>
           <img src={game.gif} alt="Hover Preview" className="w-full h-full object-cover" />
         </div>
       )}

       {/* Game Name Overlay */}
       <div className='h-[80px] sm:h-[90px] md:h-[100px] w-full bg-[#272727A3] absolute bottom-14'>
         <div className='relative w-full h-full flex flex-col justify-start items-center'>
           <div className='text-white absolute top-1 z-10 text-lg sm:text-xl md:text-2xl'>{game.gameName}</div>
           <button onClick={() => handlePlayClick(game.gameUrl)} className='absolute item-center bottom-0 z-30 bg-[#6B34CB] flex gap-2 sm:gap-3 rounded-xl w-[150px] sm:w-[160px] md:w-[170px] h-[40px] justify-center sm:h-[45px] md:h-[50px] text-white items-center px-2 '>
             <img src={Play} alt="Play" className='' />
             <p className='mt-4 text-[15px]' style={{ fontFamily: 'CustomFont, sans-serif' }}>PLAY NOW</p>
           </button>
         </div>
       </div>
  
          {/* Developer Info */}
          <div className='rounded-br-lg rounded-bl-lg w-full absolute bg-[#1C0D4D] bottom-0 z-10 h-[60px] sm:h-[65px] md:h-[70px] flex justify-center items-center pl-3 '>
            <img src={game.developerImage} alt="Developer" className='h-[24px]  w-[24px] rounded-full' />
            <div className='flex justify-center gap-10  items-center h-full w-full pt-2 md:mt-4'>
              <p className='text-white text-[11px]'>{game.developerName}</p>
              <p className='text-white text-[11px] '>Game Developer</p>
            </div>
          </div>
        </div>
      ))}
    </div>
    <Footer />
  </div>
  
  );
};

export default Games;
