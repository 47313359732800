import React, { useState } from 'react';
import Cancel from './assets/images/cancel_register.png'
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const Modalpayment = ({ isVisible, onClose }) => {
  const [paymentUrl, setPaymentUrl] = useState("");
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [transactionId, setTransactionId] = useState(null);

  const handlePayment = (amount) => {
    const payload = {
      amount: amount, // Pass the amount based on the selected plan
      currency: "INR",
      firebase_uuid: "exampleUUID", // Replace with the actual UUID
    };

    axios.post('https://कोड.com/api/v1/initiate_payment/', payload)
      .then(res => {
        const payPageUrl = res.data.pay_page_url;
        setTransactionId(res.data.unique_transaction_id);
        console.log(payPageUrl);
        console.log(res.data);
        window.location.href = payPageUrl; // Redirect to the payment page
      })
      .catch(error => {
        console.error(error);
      });
  };


  if (!isVisible) return null;

  return (
    <div className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50'>
      <div className="relative bg-white p-8 rounded-2xl shadow-2xl max-w-lg w-full">
        {/* Close Button */}
        <div className='absolute top-4 right-4 cursor-pointer'>
          <img src={Cancel} alt="close" onClick={onClose} className=' hover:opacity-80' />
        </div>

        {/* Modal Heading */}
        <h2 className="text-2xl font-bold text-center mb-8 text-gray-800"> {t("header.plan")} </h2>

        {/* Plan Options */}
        <div className='flex justify-between items-center space-x-4'>
          {/* Plan Card 1 */}
          <div className='flex flex-col justify-between items-center border-2 rounded-lg w-1/2 p-6 hover:shadow-lg transition-shadow duration-300'>
            <div className='text-center mb-4'>
              <div className='text-3xl font-semibold text-black'>₹99</div>
              <div className='text-gray-500'>30 {t("header.day")}</div>
            </div>
            <button onClick={() => handlePayment(99)} className='w-full  text-black border-2 py-2 px-4 rounded-lg hover:bg-purple-600 hover:text-white  duration-300 '>
              {t("header.start")}
            </button>
          </div>

          {/* Plan Card 2 */}
          <div className='flex flex-col justify-between items-center border-2 rounded-lg w-1/2 p-6 hover:shadow-lg transition-shadow duration-300'>
            <div className='text-center mb-4'>
              <div className='text-3xl font-semibold text-black'>₹499</div>
              <div className='text-gray-500'>1 {t("header.year")}</div>
            </div>
            <button onClick={() => handlePayment(499)}className='w-full  text-black py-2 px-4 rounded-lg hover:bg-purple-600 hover:text-white border-2 duration-300'>
              {t("header.start")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modalpayment;
