
// import React, { useEffect, useState} from "react";
// import Python from "../images/Python.png";
// import Java from "../images/Java.png";
// import { Link } from "react-router-dom";
// import axios from "axios";

// import heroImg from "./code.png";
// import success from "./success.png";
// import idea from "./idea (2).png";
// import jump from "./Group.png";
// import learning from "./learning.svg";
// import hourGlass from "./hourglass.svg";
// import certificate from "./certificate.svg";
// import teacher from "./teacher.svg";
// import liveClass from "./liveclass.png";
// import group from "./Group 9515.svg";
// import story from "./story.jpg";
// import mario from "./mario.png";
// import poster from "./hindiDivas.svg";

// import GooglePlayStore from "../images/GooglePlayStore.png";
// import carouselimg from "../images/carouselimg.png";
// import Gmail from "../images/Gmail.png";
// import LinkedIn from "../images/LinkedIn.png";
// import person1 from "../images/person1.png";
// import Footer from "./Footer";

// import Carousel from "./Carousel";
// import StudentCarousel from "./StudentCarousel";
// import DcaCarousel from "./DcaCarousel";
// import Facebook from "../images/Facebook.png";
// import Instagram from "../images/Instagram.png";
// import GmailWhite from "../images/GmailWhite.png";
// import LinkedInwhite from "../images/LinkedInwhite.png";
// import { useAuth } from "./AuthContext";

// const Homepage = () => {

  // const [paymentUrl, setPaymentUrl] = useState("");
  // const [transactionId, setTransactionId] = useState(null);
//   const {login} = useAuth();

  // const payload = {
  //   amount: 1000, // Example amount, adjust as needed
  //   currency: "INR",
  //   firebase_uuid: "exampleUUID", // Replace with the actual UUID
  // };

  // const handlePayment = (e) => {
  //   e.preventDefault();
  //   axios.post('https://कोड.com/api/v1/initiate_payment/', payload)
  //       .then(res => {
  //           const payPageUrl = res.data.pay_page_url;
  //           setTransactionId(res.data.unique_transaction_id);
  //           console.log(payPageUrl);
  //           console.log(res.data);
  //           window.location.href = payPageUrl; // Redirect to the payment page
  //       })
  //       .catch(error => {
  //           console.error(error);
  //       });
  // };

//   useEffect(() => {
//     const user = localStorage.getItem('user');
//     console.log(user)
//     if (user) {
//         // Assuming `login` is a method to set the user as logged in
//         console.log(user)
//         login();
//         // Optionally, you can set user data to state or context
//         // setUser(JSON.parse(user));
//     }
// }, []);

//   return (
//     <>
//       <div
//         className="flex justify-between items-center -mt-10" 
//         style={{ height: "235px" }}
//       >
//         <div className="mt-96" style={{ width: "106%" }}>
//           <div className="flex justify-center items-center gap-36">
//             <div className="px-3 absolute left-0 ml-20 -mt-64">
//               <img src={Python} alt="python" className="pythonImg w-32" />
//             </div>
//             <div className="flex flex-col gap-8 relative">
//               <p className="text-[4rem] main-head">
//                 Learn Coding From The Best{" "}
//               </p>
//               <p
//                 className="text-[4rem] main-head"
//                 style={{ lineHeight: "10px" }}
//               >
//                 {" "}
//                 Without Any Barrier
//               </p>
//             </div>
//             <div className="javaIcon absolute right-0 mr-[62rem] mt-28">
//               <img src={Java} alt="java" className="javaImg w-32" />
//             </div>
//             <div className="relative">
//               <img
//                 src={success}
//                 alt=""
//                 className="absolute w-48 mt-96 -ml-24 z-40"
//               />
//               <img
//                 src={heroImg}
//                 alt="heroImage"
//                 className="w-[36rem] mt-10 relative"
//               />
//               <img
//                 src={idea}
//                 alt=""
//                 className="absolute w-28 -mt-[34rem] ml-[26rem]"
//               />
//               <div className="absolute">
//                 <a href="#events">
//                   <img
//                     src={group}
//                     alt="event"
//                     className="transition -mt-16 w-[32rem] ml-5 cursor-pointer animate-bounce"
//                   />
//                 </a>
//               </div>
//             </div>
//           </div>
//           <div className="relative mx-[35rem] -mt-44 -left-96">
//             <div className=" bg-[#ffd35a0a] relative z-20 mt-20 text-xl text-black font-medium py-1 second-head">
//               <p>
//                 We are here with a solution for those students who always wanted
//               </p>
//               <p> to learn coding but can't because of language barrier</p>
//             </div>
//             <div className="absolute -inset-6 blur-lg bg-gradient-to-br from-purple-100 via-purple-100 to-purple-100 z-10"></div>
//           </div>
//         </div>
//       </div>

//       <div className="mt-[26rem] ml-[10rem]">
//         <div className="secondHomePara">
//           <div className="">
//             <div className="flex gap-14">
//               {/* <button onClick={handlePayment} className="text-xl bg-purple-700 hover:bg-purple-900 hover:transition ease-in-out delay-150 duration-300 px-12 py-3 rounded-full text-white"> Payment</button> */}
//               <Link to="/payment"><button className="text-xl bg-purple-700 hover:bg-purple-900 hover:transition ease-in-out delay-150 duration-300 px-12 py-3 rounded-full text-white"> Payment</button></Link>
//               <button className="text-xl bg-purple-700 hover:bg-purple-900 hover:transition ease-in-out delay-150 duration-300 px-12 py-3 rounded-full text-white">
//                 Start Coding
//               </button>
//               <button className="text-xl bg-purple-700 hover:bg-purple-900 hover:transition ease-in-out delay-150 duration-300 px-12 py-3 rounded-full text-white">
//                 View Courses
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* //// *********************** /// */}
//       <a
//         className="flex justify-center items-center mt-20 cursor-pointer"
//         href="#us"
//       >
//         <img src={jump} alt="down arrow" className="animate-bounce w-10" />
//       </a>

//       <div className="w-full h-96">
//         <div className="mt-24" id="us">
//           <p className="text-center mb-5 text-3xl font-bold">WHY US?</p>
//         </div>

//         <div className="four-cards 2xl:flex xl:justify-evenly 2xl:items-center mt-24 pb-10 px-28 grid grid-cols-1 place-items-center gap-4 lg:grid-cols-2">
//           <div className="flex gap-4 justify-center items-center border-purple-300 border-2 py-4 px-8 rounded-md shadow-purple-200 shadow-xl w-96 h-36">
//             <img src={learning} alt="person" className="w-24" />
//             <p className="why-text text-xl mt-4">
//               Learn Code, <br />
//               Anytime, Anyplace
//             </p>
//           </div>

//           <div className="flex gap-4  justify-center items-center border-purple-300 border-2 py-4 px-8 rounded-md shadow-purple-200 shadow-xl w-96 h-36">
//             <img src={hourGlass} alt="person" className="w-24" />
//             <p className="why-text text-xl mt-4">Lifetime access</p>
//           </div>

//           <div className="flex gap-4 justify-center items-center border-purple-300 border-2 py-4 px-8 rounded-md shadow-purple-200 shadow-xl w-96 h-36">
//             <img src={certificate} alt="person" className="w-24" />
//             <p className="why-text text-xl mt-4">Certificate</p>
//           </div>

//           <div className="flex gap-4 justify-center items-center border-purple-300 border-2 py-4 px-8 rounded-md shadow-purple-200 shadow-xl w-96 h-36">
//             <img src={teacher} alt="person" className="w-24" />
//             <p className="why-text text-xl mt-4">
//               Guided by Experts, <br />
//               Mastered by You
//             </p>
//           </div>
//         </div>
//       </div>

      

//       {/* <DcaCarousel/> */}

//       <div className="live-class 2xl:flex 2xl:justify-evenly 2xl:items-center mt-28 px-48 grid grid-cols-1 place-items-center">
//         <img src={liveClass} alt="live" className="w-[500px]"/>
//         <div className="flex flex-col items-start">
//           <div className="border-red-400 border-2 rounded-full w-72 flex justify-center items-center font-bold uppercase">
//             <h1 className="text-2xl mt-2 -ml-10">Live classes</h1>
//           </div>
//           <span className="relative flex h-4 w-4 -mt-9 ml-60">
//             <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
//             <span className="relative inline-flex rounded-full h-4 w-4 bg-red-500"></span>
//           </span>
//           <h3 className="mt-14 text-2xl ml-2">
//             Unlock your potential with interactive live classes—
//             <br />
//             learn from expert instructors in real-time!
//           </h3>
//           <button className="bg-red-500 rounded-full px-4 py-2 mt-4 text-lg ml-2 hover:scale-105 transition">
//             <Link to="/liveclass" className="text-white text-decoration-none">
//               Book Your Live Class
//             </Link>
//           </button>
//         </div>
//       </div>

//       {/* games */}
//       <div className="flex flex-col pb-10 mt-12">
//         <p className="text-center mt-10 text-3xl font-bold">
//           Start your coding journey with fun and engaging games
//         </p>
//         <div className="flex justify-evenly mt-20 px-44">
//           <div className="p-3 rounded-3xl border-2 border-neutral-300">
//             <img
//               src={story}
//               alt="crowStory"
//               className="w-[25rem] h-80 object-cover rounded-3xl ring-4 ring-sky-300 cursor-pointer hover:scale-105 transition duration-500"
//             />
//             <p className="text-2xl mt-3 font-bold text-purple-600">
//               The Thirsty Crow
//             </p>
//             <p className="text-lg text-neutral-500 font-semibold -mt-3">
//               Python
//             </p>
//             <button className="bg-purple-700 text-white rounded-full px-4 py-2 text-xl hover:scale-105 transition tracking-wider font-medium">
//               Start learning
//             </button>
//           </div>
//           <div className="p-3 rounded-3xl border-2 border-neutral-300">
//             <img
//               src={mario}
//               alt="crowStory"
//               className="w-[25rem] h-80 object-cover rounded-3xl blur-sm ring-4 ring-sky-300 cursor-not-allowed hover:scale-105 transition duration-500"
//             />
//             <p className="text-2xl mt-8 font-bold text-red-600 text-center uppercase tracking-wider">
//               Comming Soon
//             </p>
//           </div>
//           <div className="p-3 rounded-3xl border-2 border-neutral-300">
//             <img
//               src={mario}
//               alt="crowStory"
//               className="w-[25rem] h-80 object-cover rounded-3xl blur-sm ring-4 ring-sky-300 cursor-not-allowed hover:scale-105 transition duration-500"
//             />
//             <p className="text-2xl mt-8 font-bold text-red-600 text-center uppercase tracking-wider">
//               Comming Soon
//             </p>
//           </div>
//         </div>
//       </div>

//       {/* events */}
//       <div
//         id="events"
//         className="flex flex-col justify-center items-center px-96 pb-10"
//       >
//         <img
//           src={group}
//           alt="event"
//           className="transition mt-10 w-[22rem] ml-5 cursor-pointer"
//         />
//         <div className="event-box w-full h-56 border-2 border-neutral-300 mt-8 flex justify-between items-center rounded-lg">
//           <img src={poster} alt="event" className="w-52 rounded-lg ml-1 object-fill" />
//           <div></div>
//         </div>
//       </div>

//       {/* <div className='row studentRow'>
//             <p className='text-center' style={{fontSize:'32px',fontWeight:'600',color:'#1C0D4D'}}>What Did Our Students Say About Us ?</p>
//         </div> */}

//       {/* *********  2nd carousel   ******************* */}

//       {/* <StudentCarousel/> */}

//       {/* ********** end of 2nd CAROUSEL  ************** */}

//       {/* ******************************** */}

//       {/* <div className='row mb-5 text-center'>   
//             <p className='nearFooRow' style={{fontWeight:'600' ,fontSize:'32px'}}>Download Our App</p>           
//                 <div className='d-flex offset-5 nearFooBtn' style={{borderRadius:'18px' , backgroundColor:'#242424' ,width:'240px',height:'84px'}}>           
//                     <img src={GooglePlayStore} alt='GooglePlayStore' className='p-2'/>              
//                     <div className='' style={{color:'#FFFFFF'}}>
//                         <p className='pt-3 px-3' style={{fontWeight:'400',fontSize:'20px' ,lineHeight:'7px'}}>GET IT ON</p>    
//                         <p style={{fontWeight:'400',fontSize:'20px'}}>Google Play</p>                                    
//                     </div>               
//                 </div> 
//         </div>    */}

//       {/* ******************************** */}

//       {/* <div className='container mb-5 text-center'>
//                 <div className='row'>
//                     <div className='col-md-12 col-lg-12 col-sm-12'> 
//                         <p>Download Our App</p>
//                         <div className='d-flex offset-5' style={{borderRadius:'18px' , backgroundColor:'#242424' ,width:'240px',height:'84px'}}>           
//                             <img src={GooglePlayStore} alt='GooglePlayStore' className='p-2'/>              
//                             <div className='' style={{color:'#FFFFFF'}}>
//                                 <p className='pt-3 px-3' style={{fontWeight:'400',fontSize:'20px' ,lineHeight:'7px'}}>GET IT ON</p>  
//                                 <p style={{fontWeight:'400',fontSize:'20px'}}>Google Play</p>                              
//                             </div>          
//                         </div> 
//                     </div>
//                 </div>
//         </div>        
//         */}

//         <div className="text-center mt-6">
//         <p className="text-lg font-medium text-neutral-600">Our Instructor</p>
//         <p className="text-3xl uppercase font-bold">Meet Our Expert</p>
//         <p className="text-lg font-medium text-neutral-600">
//           Gain invaluable insights and skills by learning from our
//           industry-leading experts.
//         </p>
//       </div>

//       {/* ********** 1st CAROUSEL  ************** */}

//       <Carousel />

//       {/* ********** end of 1st CAROUSEL  ************** */}

//       <Footer/>
//     </>
//   );
// };

// export default Homepage;
import React, { useEffect, useState }  from "react";

import './homePage.css';

import mainVideo from './mainVideo.mp4';
import Play from './assets/play.gif';

import Whyshuniyavigyan from './assets/Why-shuniyavigyan.png';
import comprehensiveLearning from './assets/comprehensive-learning.svg';
import InteractiveCode from './assets/Interactive-code.svg';
import AI from './assets/AI.svg';
import Monetize from './assets/Monetize.svg';
import Beginner from './assets/Beginner.svg';
import AllInOne from './assets/All-in-one.svg';
import ezgifCode from './assets/ezgifCode.gif';
import codeEarn from './assets/codeEarn.gif';
import Footer from './Footer.js'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";


const Homepage = () => {
  const { t } = useTranslation();
  const [userCount, setUserCount] = useState(0);
  const [downloadCount, setDownloadCount] = useState(0);

  useEffect(() => {
    // Fetch user registered count
    const fetchUserCount = async () => {
      try {
        const response = await fetch("https://xn--11by0j.com/api/v1/user_registered_count/");
        const data = await response.json();
        setUserCount(data.user_registered_count); // assuming the response has a 'count' field
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    };

    // Fetch download count
    const fetchDownloadCount = async () => {
      try {
        const response = await fetch("https://xn--11by0j.com/api/v1/downloads/");
        const data = await response.json();
        setDownloadCount(data.number_of_downloads); // assuming the response has a 'count' field
      } catch (error) {
        console.error("Error fetching download count:", error);
      }
    };

    fetchUserCount();
    fetchDownloadCount();
  }, []);

  return (
    <>

    <div className="landing_page">
    <video playsinline autoplay muted loop>
        <source src="../codeCom/assets/mainVideo.mp4" type="video/mp4" />


      </video>


      <div className="overlay">
        <header>

          <div className="header__videoBackground">
            <video playsinline loop="loop" muted="muted" autoplay="autoplay" poster="mainVideo.mp4">
              <source type="video/mp4" src={mainVideo} />
              <source type="video/webm" src={mainVideo} />
            </video>
          </div>
        </header>

        <div className="container">
        <div className="row">

          <div className="card-deck1">
            <div className="topLeft">
              <h1 className="topLeftText topLeftText1">{t('homepage.play')}</h1>
              <h1 className="textRt topLeftText">{t('homepage.create')}</h1>
              <h1 className="topLeftText txtLft">{t('homepage.earn')}</h1>
              <p className="topLeftTextDown">{userCount} + <span className="txtThemed"> {t('homepage.user')} </span> | {downloadCount}+ <span className="txtThemed"> {t('homepage.download')} </span> </p>
            </div>
            <div className="topRight">



              <div className="box clip"></div>

            </div>

          </div>

        </div>
      </div>

      </div>


     


      <div className="container">
        <div className="row mdlTxt">
          <p className="txtBold">{t('homepage.title1')}  </p>
          <p>{t('homepage.title2')}</p>
        </div>
      </div>

      <div className="card-deck">
        <div className="card shadow-lg">
          <img className="card-img-top" src={Play} alt="img" />
          <div className="card-body">
            <h5 className="card-title card-text-title-font">{t('homepage.title3')}</h5>
            <p className="card-text card-text-font">{t('homepage.description3')}</p>
          </div>
          <div className="card-footer">
            <button type="button" className="bg-purple-700 px-4 py-2 rounded-full purple-btn text-white text-card-btn">{t('homepage.play_now')}</button>
          </div>
        </div>

        <div className="card shadow-lg">
          <img className="card-img-top" src={ezgifCode} alt="img" />
          <div className="card-body">
            <h5 className="card-title card-text-title-font">{t('homepage.title4')}</h5>
            <p className="card-text card-text-font">{t('homepage.description4')}</p>
          </div>
          <div className="card-footer">
            <button type="button" className="bg-purple-700 px-4 py-2 rounded-full purple-btn text-white text-card-btn">{t('homepage.learn')}</button>  <button type="button" className="bg-purple-700 px-4 py-2 rounded-full purple-btn text-white">{t('homepage.create_title4')}</button>
          </div>
        </div>
        <div className="card shadow-lg">
          <img className="card-img-top" src={codeEarn} alt="img" />
          <div className="card-body">
            <h5 className="card-title card-text-title-font">{t('homepage.title5')}</h5>
            <p className="card-text card-text-font">{t('homepage.description5')}</p>
          </div>
          <div className="card-footer">
            <button type="button" className="bg-purple-700 px-4 py-2 rounded-full purple-btn text-white text-card-btn">{t('homepage.earn_title5')}</button>
          </div>
        </div>
      </div>

      <div className="container text-center txtSpace">
        <div className="row">
          <h1>{t('homepage.why')}</h1>
        </div>
      </div>

      <div className="container">

        <div className="row rowDown">
          <div className="col contTextCentre">
            <img src={Whyshuniyavigyan} className="img-fluid" alt="" />
          </div>
          <div className="col textLists">
            <div className="section-modeling-operation">
              <div className="container">
                <div className="row">





                  <table>
                    <tr>
                      <th className="listIconBg">
                        <img className="imageIcon" src={comprehensiveLearning} alt="img" />
                      </th>
                      <td><span className="subheader"> {t('homepage.benefit1')}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="listIconBg">
                        <img className="imageIcon" src={InteractiveCode} alt="img" />
                      </th>
                      <td><span className="subheader"> {t('homepage.benefit2')}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="listIconBg">
                        <img className="imageIcon" src={AI} alt="img" />
                      </th>
                      <td><span className="subheader"> {t('homepage.benefit3')}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="listIconBg">
                        <img className="imageIcon" src={Monetize} alt="img" />
                      </th>
                      <td><span className="subheader"> {t('homepage.benefit4')}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="listIconBg" >
                        <img className="imageIcon" src={Beginner} alt="img" />
                      </th>
                      <td><span className="subheader"> {t('homepage.benefit5')}</span>
                      </td>
                    </tr>
                    <tr>
                      <th className="listIconBg">
                        <img className="imageIcon" src={AllInOne} alt="img" />
                      </th>
                      <td><span className="subheader"> {t('homepage.benefit6')}</span>
                      </td>
                    </tr>
                  </table>


                </div>
              </div>
            </div>
          </div>
        </div>
        

      </div>
    </div>

     
      <Footer />

    </>
  );
};

export default Homepage;
