import React from "react";
import {
	FaLinkedin,
	FaFacebook,
	FaInstagram,
	FaEnvelope,
} from "react-icons/fa";

const Footer = () => {
	return (
		<footer className="bg-gray-900 text-white py-8">
			<div className="container mx-auto flex justify-between items-start px-4">
				<div className="w-1/2">
					<h2 className="text-lg font-bold">
						CODE <br /> by Shuniyavigyan
					</h2>
					<p className="mt-4 text-sm">
						We are an online platform that aims to teach students coding in
						their native language. This is to help all those students out there
						who want to learn coding but can’t because of a language barrier!
					</p>

					<div className="mt-4">
						<p className="text-sm">
							<strong>Email: </strong>
							<a
								href="mailto:ankit@shuniyavigyan.com"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								ankit@shuniyavigyan.com
							</a>
						</p>
						<p className="text-sm">
							<strong>Phone: </strong>+919234531332
						</p>
					</div>

					<div className="flex space-x-4 mt-4">
						<a
							href="#"
							className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white"
						>
							<FaLinkedin className="w-5 h-5" />
						</a>
						<a
							href="#"
							className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white"
						>
							<FaFacebook className="w-5 h-5" />
						</a>
						<a
							href="#"
							className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white"
						>
							<FaInstagram className="w-5 h-5" />
						</a>
						<a
							href="#"
							className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white"
						>
							<FaEnvelope className="w-5 h-5" />
						</a>
					</div>
				</div>

				<div className="w-1/4 flex-col">
					<h3 className="text-lg font-bold">Shuniyavigyan</h3>
					<ul className="mt-4 space-y-2">
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Home
							</a>
						</li>
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Editor
							</a>
						</li>
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Games
							</a>
						</li>
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Assets
							</a>
						</li>
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Courses
							</a>
						</li>
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Downloads
							</a>
						</li>
					</ul>
				</div>

				<div className="flex-col">
					<h3 className="text-lg font-bold">Terms</h3>
					<ul className="mt-4 space-y-2">
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								About Us
							</a>
						</li>
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Contact Us
							</a>
						</li>
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Privacy Policy
							</a>
						</li>
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Refund Policy
							</a>
						</li>
						<li>
							<a
								href="#"
								className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
							>
								Terms & Condition
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div className="text-center mt-8 text-sm text-gray-400">
				&copy; 2023 Copyright:{" "}
				<a
					href="https://shuniyavigyan.com"
					className="hover:shadow-lg hover:scale-105 transition-all duration-200 ease-in-out text-white no-underline"
				>
					shuniyavigyan.com
				</a>
			</div>
		</footer>
	);
};

export default Footer;
