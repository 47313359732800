import logo from "./logo.svg";
import "./App.css";
import "./css/style.css";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import { useState, createContext } from "react";

// import loginBg from './images/loginBg.jpg';
// import  Login2  from './components/Login2';
import "font-awesome/css/font-awesome.min.css";
import Register from "./components/Register";
import Profile from "./components/Profile.js";
import Transaction from "./components/Transaction.js";
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import Editor from "./components/Editor";
import LiveClassAccess from "./components/LiveClassAccess";
import BookLiveClass from "./components/BookLiveClass";
import GamePage from "./components/GamePage.js";
import Courses from "./components/Courses";
import { createBrowserRouter, Outlet } from "react-router-dom";
import Output from "./components/Output";
import Carousel from "./components/Carousel";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme.js";
// import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "react-simple-keyboard/build/css/index.css";
import CoursesPage from "./components/CoursesPage"; // Update import to CoursesPage
import ContactUs from "./components/ContactUs.js";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import RefundPolicy from "./components/RefundPolicy.js";
import TermsAndCondition from "./components/TermsAndCondition.js";
import AboutUs from "./components/AboutUs.js";
import CustomNavbar from "./components/CustomNavbar.js";

// import Prepare from "./components/Prepare.js"

import LanguagesGamePage from "./components/OpeningLanguage.js";
import Prepare from "./components/Prepare.js";
import Prepare3 from "./components/Prepare3.js";
import Prepare4 from "./components/Prepare4.js";
import Payment from "./components/Payment.js";
import Games from "./components/Games.js";
import Downloads from "./components/Downloads.js";
import Games_game from "./components/Games_game.js";
import CoursePage from "./components/CoursePage/CoursePage.jsx";

// export const AppContext = createContext();

function App() {
	// const [isNavbarVisible, setIsNavbarVisible] = useState(true);

	// const showNavbar = () => {
	//   setIsNavbarVisible(true);
	// };

	// const hideNavbar = () => {
	//   setIsNavbarVisible(false);
	// };

	return (
		<>
			{/* <Header/>  */}
			<Outlet />

			{/* <AppContext.Provider value={{ showNavbar, hideNavbar }}>  
    
    </AppContext.Provider> */}
		</>
	);
}

export const appRouter = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		children: [
			{
				path: "/",
				element: (
					<>
						<CustomNavbar />
						<Homepage />
					</>
				),
			},
			// {
			//   path : "/editor",
			//   element :
			//   <ChakraProvider theme={theme} >  <Editor  /> </ChakraProvider>

			// },
			{
				path: "/downloads",
				element: (
					<>
						<Header />
						<Downloads />
					</>
				),
			},
			{
				path: "/payment",
				element: (
					<>
						<Header />
						<Payment />
					</>
				),

				path: "/prepare",
				element: <Prepare />,
			},
			{
				path: "/prepare3/:topicName",
				element: <Prepare3 />,
			},
			{
				path: "/prepare4/:topicName/:problemName",
				element: <Prepare4 />,
			},
			{
				path: "/courses",
				element: (
					<>
						<Header />
						<CoursesPage />
					</>
				), // Update element to CoursesPage
			},
			{
				path: "/course/1",
				element: (
					<>
						<Header />
						<CoursePage />
					</>
				),
			},
			{
				path: "/chooseLanguage",
				element: <LanguagesGamePage />,
			},
			{
				path: "/liveclass",
				element: <LiveClassAccess />,
			},
			{
				path: "/bookclass",
				element: <BookLiveClass />,
			},
			{
				path: "/aboutus",
				element: (
					<>
						<Header />
						<AboutUs />
					</>
				),
			},
			{
				path: "/contactus",
				element: (
					<>
						<Header />
						<ContactUs />
					</>
				),
			},
			{
				path: "/privacypolicy",
				element: (
					<>
						<Header />
						<PrivacyPolicy />
					</>
				),
			},
			{
				path: "/refundpolicy",
				element: (
					<>
						<Header />
						<RefundPolicy />
					</>
				),
			},
			{
				path: "/termsandcondition",
				element: (
					<>
						<Header />
						<TermsAndCondition />
					</>
				),
			},
			{
				path: "/profile",
				element: (
					<>
						<Header />
						<Profile />
					</>
				),
			},
			{
				path: "/transaction",
				element: (
					<>
						<Header />
						<Transaction />
					</>
				),
			},
			{
				path: "/prepare",
				element: <Prepare />,
			},
			{
				path: "/games",
				element: (
					<>
						<Header />
						<Games />
					</>
				),
			},
			// {
			//   path : "/gamepage",
			//   element: <GamePage />,
			// },
			// {
			//   path : "/output",
			//   element :({ showNavbar, hideNavbar }) => (
			//    <Output showNavbar={showNavbar} hideNavbar={hideNavbar} />
			//   )
			// },
		],
	},
	{
		path: "/editor",
		element: (
			<ChakraProvider theme={theme}>
				{" "}
				<Editor />{" "}
			</ChakraProvider>
		),
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/register",
		element: <Register />,
	},
	{
		path: "/gamepage",
		element: <GamePage />,
	},
	{
		path: "/game/:gameUrl",
		element: <Games_game />,
	},
]);

export default App;
