import React from "react";
import logo from "./shuniyavigyanLogo.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer className="w-full h-[580px] bg-[#6519a7] flex flex-col pt-16 text-[#F7EFE5]">
        <div className="flex justify-evenly items-center">
          <div className="1st-sec flex flex-col">
            <div className="logo-part flex items-center gap-6">
              <img src={logo} alt="logo" className="w-16" />
              <div className="">
                <h2 className="tracking-wider text-[26px]">{t('footer.logo')}</h2>
                <h2 className="italic lowercase font-normal  text-[20px]">
                  {t('footer.brand')}
                </h2>
              </div>
            </div>

            <div className="about-part flex w-[400px] mt-4">
              <h2 className="tracking-wider mt-1 text-[18px] font-thin text-justify leading-loose">
               {t('footer.description')}
              </h2>
            </div>

            <div className="about-part flex flex-col mt-4">
              <h1 className="text-[18px]">
                {t('footer.email')}:{" "}
                <a
                  href="mailto:ankit@shuniyavigyan.com"
                  className="ml-5 text-[#F7EFE5] decoration-transparent"
                >
                  ankit@shuniyavigyan.com
                </a>
              </h1>
              <h1 className="text-[18px]">
                {t('footer.phone')}:{" "}
                <a
                  href="tel:+919234531332"
                  className="ml-5 text-[#F7EFE5] decoration-transparent"
                >
                  +919234531332
                </a>
              </h1>
            </div>

            <div className="flex mt-5 gap-6">
              <a href="#" className="text-[#F7EFE5]">
                <LinkedInIcon sx={{ fontSize: 40 }} />
              </a>
              <a href="#" className="text-[#F7EFE5]">
                <FacebookIcon sx={{ fontSize: 40 }} />
              </a>
              <a href="#" className="text-[#F7EFE5]">
                <InstagramIcon sx={{ fontSize: 40 }} />
              </a>
              <a href="#" className="text-[#F7EFE5]">
                <EmailIcon sx={{ fontSize: 40 }} />
              </a>
            </div>
          </div>

          <div className="2nd-sec flex flex-col -mt-32">
            <h1 className="text-[24px] tracking-wider uppercase">{t('footer.Suniya')}</h1>
            <ul className="mt-10 -ml-7 text-[18px] flex flex-col gap-3">
            <li>
                <Link
                  to="/"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.home')}
                </Link>
              </li>
              <li>
                <Link
                  to="/editor"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.editor')}
                </Link>
              </li>
              <li>
                <Link
                  to="/games"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.game')}
                </Link>
              </li>
              <li>
                <Link
                  to="/courses"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.course')}
                </Link>
              </li>
              
              <li>
                <Link
                  to="/downloads"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.download')}
                </Link>
              </li>
            </ul>
          </div>

          <div className="3rd-sec flex flex-col -mt-32">
            <h1 className="text-[24px] tracking-wider uppercase">{t('footer.legal')}</h1>
            <ul className="mt-9 -ml-7 text-[18px] flex flex-col gap-3">
            <li>
                <Link
                  to="/aboutus"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.about')}
                </Link>
              </li>
              <li>
                <Link
                  to="/contactus"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.contact')}
                </Link>
              </li>
              <li>
                <Link
                  to="/privacypolicy"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.privacy')}
                </Link>
              </li>
              <li>
                <Link
                  to="/refundpolicy"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.refund')}
                </Link>
              </li>
              <li>
                <Link
                  to="/termsandcondition"
                  className="text-[#F7EFE5] no-underline tracking-wider hover:underline-offset-8 hover:underline font-normal"
                >
                  {t('footer.terms')}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="copyright text-center mt-2">
            <h3 className="text-[16px] tracking-widest font-normal">© 2023 {t('footer.copyright')}: shuniyavigyan.com</h3>
        </div>
      </footer>
    </>
  );
};

export default Footer;
