import React, { useState, useEffect } from 'react';
import Modal from './Modalpayment.js';  // Assuming Modal is in the same directory
import { useTranslation } from 'react-i18next';
import './Membership.css';  // Import the external CSS file

const Membership = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  // Check if the user is logged in by fetching from localStorage
  const isLoggedIn = localStorage.getItem('user') ? true : false;

  const openModal = () => {
    if (isLoggedIn) {
      setModalVisible(true);
    } else {
      setShowNotification(true); // Show the dynamic bar if not logged in
      setTimeout(() => setShowNotification(false), 3000); // Hide after 3 seconds
    }
  };

  const closeModal = () => setModalVisible(false);

  return (
    <div className="relative">
      <button
        className='bg-purple-600 px-3 py-2 rounded-xl text-white'
        onClick={openModal}
      >
        {t("header.member")}
      </button>

      {/* Modal Component */}
      <Modal isVisible={isModalVisible} onClose={closeModal} />

      {/* Dynamic Notification Bar */}
      {showNotification && (
        <div className="notification-bar">
          Register yourself first!
        </div>
      )}
    </div>
  );
};

export default Membership;
